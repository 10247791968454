@import '../../mixins';

.demo {
    background: #FAFCFF;
    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        background: no-repeat center/cover; }
    &__title {
        color: #fff;
        line-height: 1.3;
        font-weight: bold;
        font-family: 'Stapel', sans-serif; }
    &__text {
        color: #fff;
        text-align: center; }
    &__form {
        display: flex;
        justify-content: center; }
    &__input {
        border: 0;
        outline: 0;
        padding: 0;
        color: #0C131D;
        background: #fff;
        line-height: 1.3;
        font-style: normal;
        font-weight: normal;
        font-family: Inter, sans-serif; }
    @include mediaRapture('desktop') {
        padding: pxvh(132) 0 pxvh(152);
        &__content {
            margin: 0 auto;
            width: pxvh(1000);
            height: pxvh(460);
            border-radius: pxvh(100);
            @include webpRetina('demoBg.jpg');
            box-shadow: 0 pxvh(64) pxvh(100) rgba(0, 40, 125, 0.3); }
        &__title {
            font-size: pxvh(44); }
        &__text {
            font-size: pxvh(17);
            line-height: pxvh(34); }
        &__form {
            margin-top: pxvh(25); }
        &__input {
            width: pxvh(370);
            height: pxvh(60);
            padding: 0 pxvh(20);
            font-size: pxvh(17);
            border-radius: pxvh(10);
            box-shadow: 0 pxvh(4) pxvh(8) rgba(0, 0, 0, 0.06); }
        &__action {
            flex-shrink: 0;
            padding: 0 pxvh(27);
            margin-left: pxvh(5);
            box-shadow: 0 pxvh(4) pxvh(8) rgba(0, 0, 0, 0.06); } }
    @include mediaRapture('mobile') {
        padding-bottom: pxvw(75);
        &__content {
            width: 100%;
            height: pxvw(331);
            border-radius: pxvw(40);
            box-shadow: 0 pxvw(30) pxvw(70) rgba(1, 130, 250, 0.4);
            @include webpRetina('demoMobileBg.jpg'); }
        &__form {
            align-items: center;
            flex-direction: column; }
        &__input {
            width: pxvw(319);
            height: pxvw(60);
            text-align: center;
            padding: 0 pxvw(20);
            border-radius: pxvw(10);
            margin: pxvw(14) 0 pxvw(10);
            box-shadow: 0 pxvw(4) pxvw(8) rgba(0, 0, 0, 0.06); } } }
