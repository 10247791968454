@import '../../mixins';

.offer {
    color: #656565;
    background: #FAFCFF;
    position: relative;
    z-index: 1;
    &__title,
    &__subtitle {
        color: #404040;
        font-weight: bold;
        text-align: center;
        font-family: 'Stapel', sans-serif; }
    &__text {
        text-align: center; }
    &__cards {
        display: flex;
        justify-content: center; }
    &__card {
        flex-shrink: 0;
        display: flex;
        background: #fff;
        position: relative;
        align-items: center;
        flex-direction: column; }
    &__body {
        text-align: center; }
    &__icon {
        position: absolute;
        pointer-events: none;
        background: no-repeat center/contain; }
    &__card_report &__icon {
        @include webpRetina('offerReport.png'); }
    &__card_data &__icon {
        @include webpRetina('offerData.png'); }
    @include mediaRapture('desktop') {
        padding-top: pxvh(87);
        &__title {
            width: pxvh(1006);
            font-size: pxvh(53);
            line-height: pxvh(58);
            margin: 0 auto 0; }
        &__text {
            margin: pxvh(16) auto 0;
            width: pxvh(806);
            font-size: pxvh(23);
            line-height: pxvh(30); }
        &__cards {
            margin-top: pxvh(75);
            gap: 0 pxvh(24); }
        &__card {
            width: pxvh(444);
            height: pxvh(384);
            border-radius: pxvh(60);
            box-shadow: 0 pxvh(34) pxvh(60) rgba(61, 89, 147, 0.15); }
        &__card_data {
            padding-top: pxvh(206); }
        &__card_report {
            padding-top: pxvh(204); }
        &__subtitle {
            font-size: pxvh(30);
            line-height: pxvh(33);
            width: pxvh(353); }
        &__body {
            margin-top: pxvh(20);
            font-size: pxvh(20);
            line-height: pxvh(30); }
        &__card_data &__body {
            width: pxvh(387); }
        &__card_report &__body {
            width: pxvh(370); }
        &__card_report &__icon {
            top: pxvh(-33);
            left: pxvh(120);
            width: pxvh(199);
            height: pxvh(200); }
        &__card_data &__icon {
            top: pxvh(-26);
            left: pxvh(122);
            width: pxvh(210);
            height: pxvh(210); } }
    @include mediaRapture('mobile') {
        &__title {
            margin: 0 auto 0;
            width: pxvw(330);
            font-weight: 700;
            line-height: pxvw(37, 320);
            font-size: pxvw(34, 320); }
        &__text {
            margin: pxvw(16, 320) auto 0;
            width: pxvw(330);
            font-weight: 400;
            font-size: pxvw(15, 320);
            line-height: pxvw(19, 320); }
        &__cards {
            align-items: center;
            flex-direction: column; }
        &__card {
            height: pxvw(242, 320);
            width: pxvw(280, 320);
            border-radius: pxvw(50);
            padding-bottom: pxvw(34);
            box-shadow: 0 pxvw(34) pxvw(60) rgba(61, 89, 147, 0.15); }
        &__subtitle {
            line-height: 1.12;
            width: pxvw(280);
            font-size: pxvw(19, 320); }
        &__body {
            margin-top: pxvw(8, 320);
            width: pxvw(245, 320);
            font-size: pxvw(13, 320);
            line-height: pxvw(18, 320); }
        &__card_report {
            margin-top: pxvw(42, 320);
            padding-top: pxvw(132, 320); }
        &__card_report &__icon {
            top: pxvw(-23, 320);
            left: pxvw(70, 320);
            width: pxvw(137, 320);
            height: pxvw(138, 320); }
        &__card_data {
            margin-top: pxvw(40, 320);
            padding-top: pxvw(130, 320); }
        &__card_data &__icon {
            top: pxvw(-28);
            left: pxvw(88);
            width: pxvw(124, 320);
            height: pxvw(138, 320); } } }
