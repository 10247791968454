@import '../../mixins';

.benefits {
    background: #FAFCFF;
    &__title {
        color: #404040;
        font-weight: bold;
        line-height: 1.12;
        text-align: center;
        font-family: 'Stapel', sans-serif; }
    &__text {
        color: #656565;
        text-align: center; }
    &__list {
        display: flex;
        justify-content: center; }
    &__item {
        display: flex;
        flex-shrink: 0;
        background: #fff;
        align-items: center;
        flex-direction: column;
        &:before {
            content: '';
            display: block;
            background: no-repeat center/contain; }
        &_pulse:before {
            @include webpRetina('benefitPulse.png'); }
        &_trends:before {
            @include webpRetina('benefitTrends.png'); }
        &_monitoring:before {
            @include webpRetina('benefitMonitoring.png'); } }
    &__caption {
        color: #656565;
        text-align: center; }
    @include mediaRapture('desktop') {
        padding-top: pxvh(118);
        &__title {
            margin: 0 auto;
            width: pxvh(776);
            font-size: pxvh(53);
            line-height: pxvh(58); }
        &__text {
            width: pxvh(776);
            font-size: pxvh(23);
            line-height: pxvh(30);
            margin: pxvh(16) auto 0; }
        &__list {
            margin-top: pxvh(68); }
        &__item {
            margin-right: pxvh(35);
            border-radius: pxvh(60);
            padding: pxvh(43) pxvh(35) pxvh(40);
            box-shadow: 0 pxvh(34) pxvh(60) rgba(61, 89, 147, 0.15);
            &:last-child {
                margin-right: 0; }
            &:before {
                width: pxvh(222);
                height: pxvh(237); } }
        &__caption {
            width: pxvh(290);
            font-size: pxvh(18);
            line-height: pxvh(33); } }
    @include mediaRapture('mobile') {
        padding-top: pxvw(172);
        padding-bottom: pxvw(75);
        &__title {
            line-height: pxvw(37, 320);
            font-size: pxvw(34, 320);
            width: pxvw(330);
            margin: 0 auto 0; }
        &__text {
            width: pxvw(280, 320);
            font-size: pxvw(15, 320);
            line-height: pxvw(19, 320);
            margin: pxvw(16, 320) auto 0; }
        &__list {
            flex-direction: column; }
        &__item {
            width: pxvw(325);
            margin: pxvw(25) auto 0;
            border-radius: pxvw(40);
            padding: pxvw(21) pxvw(25) pxvw(25);
            box-shadow: 0 pxvw(34) pxvw(60) rgba(61, 89, 147, 0.15);
            &:before {
                width: pxvw(204);
                height: pxvw(218); } }
        &__caption {
            font-size: pxvw(16);
            line-height: pxvw(27); } } }
