@import '../../mixins';

.customers {
    background: #FAFCFF;
    &__title,
    &__subtitle {
        line-height: 1.12;
        color: #404040;
        font-weight: bold;
        text-align: center;
        font-family: 'Stapel', sans-serif; }
    &__text {
        color: #656565;
        text-align: center; }
    &__items {
        display: flex; }
    &__pic {
        position: relative;
        background: rgba(#fff, 0.4);
        &:before {
            content: '';
            display: block;
            position: absolute;
            background: #fff no-repeat center/contain; } }
    &__item_airline &__pic:before {
        @include webpRetina('airline.png'); }
    &__item_airport &__pic:before {
        @include webpRetina('airport.png'); }
    &__item_ota &__pic:before {
        @include webpRetina('ota.png'); }
    @include mediaRapture('desktop') {
        padding-top: pxvh(96);
        display: flex;
        align-items: center;
        flex-direction: column;
        &__title {
            width: pxvh(732);
            font-size: pxvh(54); }
        &__text {
            width: pxvh(732);
            font-size: pxvh(24);
            margin-top: pxvh(16);
            line-height: pxvh(39); }
        &__subtitle {
            font-size: pxvh(24);
            margin-top: pxvh(24); }
        &__items {
            margin-top: pxvh(76); }
        &__item {
            margin-right: pxvh(100);
            &:last-child {
                margin-right: 0; } }
        &__pic {
            width: pxvh(240);
            height: pxvh(240);
            border-radius: pxvh(73);
            box-shadow: 0 pxvh(20) pxvh(70) rgba(12, 116, 254, 0.25);
            &:before {
                top: pxvh(17);
                left: pxvh(17);
                width: pxvh(206);
                height: pxvh(206);
                border-radius: pxvh(60);
                box-shadow: 0 pxvh(20) pxvh(70) rgba(12, 116, 254, 0.25); } }
        &__action {
            margin-top: pxvh(98);
            padding: 0 pxvh(50); } }
    @include mediaRapture('mobile') {
        padding: pxvw(48, 320) pxvw(20, 320) pxvw(72) pxvw(20, 320);
        &__title {
            line-height: 1;
            width: pxvw(330);
            font-size: pxvw(42);
            margin: 0 auto 0; }
        &__text {
            width: pxvw(330);
            font-size: pxvw(18);
            line-height: pxvw(33);
            margin: pxvw(7) auto 0; }
        &__subtitle {
            font-size: pxvw(22);
            margin-top: pxvw(11); }
        &__items {
            align-items: center;
            flex-direction: column; }
        &__item {
            margin-top: pxvw(40); }
        &__pic {
            width: pxvw(215);
            height: pxvw(215);
            border-radius: pxvw(62);
            box-shadow: 0 pxvw(20) pxvw(70) rgba(12, 116, 254, 0.25);
            &:before {
                top: pxvw(15);
                left: pxvw(15);
                width: pxvw(185);
                height: pxvw(185);
                border-radius: pxvw(50);
                box-shadow: 0 pxvw(20) pxvw(70) rgba(12, 116, 254, 0.25); } }
        &__action {
            margin: pxvw(20, 320) auto 0;
            padding: 0 pxvw(50); } } }
