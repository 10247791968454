@import '../../mixins';

.header {
    background: $blueBrand;
    &__inner {
        display: flex;
        margin: 0 auto;
        align-items: center; }
    &__logo {
        font-size: 0;
        display: block;
        background: url(../../assets/logo.svg) no-repeat center/contain;
        &:lang(en) {
            background-image: url(../../assets/logo_en.svg); } }
    &__menu {
        display: flex;
        margin-left: auto; }
    &__link {
        font-weight: 600;
        line-height: 1.3;
        text-decoration: none;
        color: rgba(#fff, 0.72);
        transition: color 300ms ease;
        &:hover {
            color: #fff; } }
    @include mediaRapture('desktop') {
        padding-top: pxvh(10);
        &__inner {
            height: pxvh(50);
            width: pxvh(1200);
            padding-left: pxvh(22); }
        &__logo {
            width: pxvh(144);
            height: pxvh(40); }
        &__link {
            font-size: pxvh(15);
            margin-right: pxvh(24);
            &:last-child {
                margin-right: 0; } } }
    @include mediaRapture('mobile') {
        padding-left: pxvw(32, 320);
        &__menu {
            display: none; }
        &__logo {
            width: pxvw(120);
            height: pxvw(64, 320); } } }
