@import '../../mixins';

.data {
    color: #656565;
    background: #FAFCFF;
    position: relative;
    z-index: 1;
    &__title {
        color: #404040;
        font-weight: bold;
        text-align: center;
        font-family: 'Stapel', sans-serif; }
    &__subtitle {
        color: #404040;
        font-weight: bold;
        font-family: 'Stapel', sans-serif; }
    &__text {
        text-align: center; }
    &__cards {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center; }
    &__card {
        flex-shrink: 0;
        display: flex;
        background: #fff;
        position: relative;

        align-items: center;
        justify-content: space-between; }
    &__card_content {
        display: flex;
        position: relative;
        flex-direction: column; }
    &__body_wrapper {
        display: flex;
        flex-direction: column;
        height: 100%; }

    &__body {
        position: relative;
        &::after {
            content: '';
            display: block;
            position: absolute;
            background: #F5A623;
            border-radius: 50%; } }
    &__icon {
        pointer-events: none;
        background: no-repeat center/contain; }
    &__card_search &__icon {
        @include webpRetina('search.png'); }
    &__card_price &__icon {
        @include webpRetina('price.png'); }
    &__card_passengers &__icon {
        @include webpRetina('passengers.png'); }
    @include mediaRapture('desktop') {
        padding-top: pxvh(87);
        padding-bottom: pxvh(87);
        &__title {
            width: pxvh(1006);
            font-size: pxvh(53);
            line-height: pxvh(58);
            margin: 0 auto 0; }
        &__text {
            margin: pxvh(16) auto 0;
            width: pxvh(806);
            font-size: pxvh(23);
            line-height: pxvh(30); }
        &__cards {
            margin-top: pxvh(40);
            gap: pxvh(24); }
        &__card {
            width: pxvh(924);
            padding: pxvh(50) pxvh(55) pxvh(40);
            border-radius: pxvh(60);
            box-shadow: 0 pxvh(24) pxvh(80) rgba(12, 116, 254, 0.15); }
        &__subtitle {
            font-size: pxvh(30);
            line-height: pxvh(33);
            width: pxvh(353); }
        &__body_wrapper {
           gap: pxvh(13);
           max-width: pxvh(460);
           margin-top: pxvh(22); }
        &__body {
            font-size: pxvh(20);
            line-height: pxvh(30);
            padding-left: pxvh(25); }
        &__body {
            &::after {
                top: pxvh(11);
                left: 0;
                width: pxvh(10);
                height: pxvh(10); } }
        &__body_single {
            font-size: pxvh(20);
            line-height: pxvh(30); }
        &__card_price &__icon {
            width: pxvh(299);
            height: pxvh(220); }
        &__card_passengers &__icon {
            width: pxvh(330);
            height: pxvh(280); }
        &__card_search &__icon {
            width: pxvh(250);
            height: pxvh(190);
            margin-right: pxvh(30); } }
    @include mediaRapture('mobile') {
        padding-top: pxvh(57);
        padding-bottom: pxvh(57);
        &__title {
            margin: 0 auto 0;
            width: pxvw(330);
            font-weight: 700;
            line-height: pxvw(37, 320);
            font-size: pxvw(34, 320); }
        &__text {
            margin: pxvw(16, 320) auto 0;
            width: pxvw(330);
            font-weight: 400;
            font-size: pxvw(15, 320);
            line-height: pxvw(19, 320); }
        &__cards {
            align-items: center;
            flex-direction: column;
            margin-top: pxvh(30);
            order: -1;
            gap: pxvh(30); }
        &__card {
            flex-direction: column-reverse;
            width: pxvw(280, 320);
            border-radius: pxvw(30);
            gap: pxvh(15);
            padding: pxvw(15) pxvw(15) pxvw(20);
            box-shadow: 0 pxvw(34) pxvw(60) rgba(61, 89, 147, 0.15);

            &_content {
                gap: pxvh(30); } }
        &__subtitle {
            line-height: 1.12;
            font-size: pxvw(24, 320); }
        &__body_wrapper {
           gap: pxvh(30); }
        &__body {
            font-size: pxvh(25);
            line-height: pxvh(30);
            padding-left: pxvh(30); }
        &__body {
            &::after {
                top: pxvh(9);
                left: 0;
                width: pxvh(15);
                height: pxvh(15); } }
        &__body_single {
            font-size: pxvh(25);
            line-height: pxvh(30); }
        &__card_price &__icon {
            width: pxvh(240);
            height: pxvh(240);
            margin-bottom: pxvh(-20); }
        &__card_passengers &__icon {
            width: pxvh(260);
            height: pxvh(270);
            margin-bottom: pxvh(-45); }
        &__card_search &__icon {
            width: pxvh(240);
            height: pxvh(210); } } }
