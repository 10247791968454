$blueBrand: #0656FE;
$desktopContentWidth: 1440;
$bounceEasing: cubic-bezier(0.175, 0.885, 0.32, 1.275);

$outOfAspectRatio: false;

$factor: 0;

$breakpoints: ('mobile': 479px, 'desktop': 480px);
$breakpointFactors: ('mobile': 375, 'desktop': 900);

@mixin mediaRapture($breakpoint) {
    $max-width: map_get($breakpoints, $breakpoint);
    $factor: map_get($breakpointFactors, $breakpoint) !global;
    @if ($breakpoint == 'mobile') {
        @media (max-width: $max-width) {
            @content; } }
    @else {
        @media (min-width: $max-width) {
            $outOfAspectRatio: false !global;
            @content; }
        @media (min-width: $max-width) and (max-aspect-ratio: 16/10) {
            $outOfAspectRatio: true !global;
            @content; } } }


@function pxvw($px, $fac: $factor) {
    @return $px / $fac * 100vw; }

@function pxvh($px, $fac: $factor) {
    @if ($outOfAspectRatio) {
        @return $px * 0.62 / $factor * 100vw; }
    @return $px / $factor * 100vh; }

$retina: 'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)';

@mixin retina($url) {
    $path: './assets/img/';
    $filePath: str-slice($url, 0, -5);
    $ext: str-slice($url, -3);

    background-image: url($path + $filePath + '.' + $ext);
    @media #{$retina} {
        background-image: url($path + $filePath + '@2x' + '.' + $ext); } }
@mixin webpRetina($url) {
    $path: './assets/img/';
    $filePath: str-slice($url, 0, -5);
    $ext: str-slice($url, -3);

    background-image: url($path + $filePath + '.' + $ext);
    .webp-supported & {
        background-image: url($path + $filePath + '.webp'); }
    @media #{$retina} {
        background-image: url($path + $filePath + '@2x' + '.' + $ext);
        .webp-supported & {
            background-image: url($path + $filePath + '@2x' + '.webp'); } } }
