@import '../../mixins';

$socials: 'vkontakte', 'telegram', 'twitter', 'tiktok', 'dzen', 'viber', 'youtube';

.footer {
    display: flex;
    background: $blueBrand;
    align-items: center;
    flex-direction: column;
    font-family: 'Open Sans', sans-serif;
    &__logo {
        font-size: 0;
        display: block;
        background: url(../../assets/logo.svg) no-repeat (center / contain);
        &:lang(en) {
            background-image: url(../../assets/logo_en.svg); } }
    &__socials {
        display: flex;
        justify-content: center; }
    &__social {
        color: #fff;
        display: flex;
        text-decoration: none;
        &-icon {
            display: block;
            background: no-repeat (center / contain);
            &_vkontakte {
                background-image: url(../../assets/footerVK.svg); }
            &_telegram {
                background-image: url(../../assets/footerTelegram.svg); }
            &_tiktok {
                background-image: url(../../assets/footerTiktok.svg); }
            &_dzen {
                background-image: url(../../assets/footerDzen.svg); }
            &_twitter {
                background-image: url(../../assets/footerTwitter.svg); }
            &_viber {
                background-image: url(../../assets/footerViber.svg); }
            &_youtube {
                background-image: url(../../assets/footerYoutube.svg); }
            &_ok {
                background-image: url(../../assets/footerOK.svg); }
            &_psgr {
                background-image: url(../../assets/footerPSGR.svg); } }
        &-text {
            font-weight: bold; } }
    &__menu {
        &-item {
            color: #fff;
            text-decoration: none;
            &:before {
                content: '•'; }
            &:first-child:before {
                content: none; } } }
    &__copyright {
        color: #fff; }
    &__lang {
        border: 0;
        outline: 0;
        padding: 0;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        box-shadow: none;
        font-weight: 600;
        line-height: 1.2;
        font-style: normal;
        background: transparent;
        font-family: 'Inter', sans-serif;
        &:before {
            content: '';
            display: block;
            flex-shrink: 0;
            background: url(../../assets/ru.svg) no-repeat center/cover; }
        &.en:before {
            background-image: url(../../assets/en.svg); } }
    @include mediaRapture('desktop') {
        padding-top: pxvh(70);
        padding-bottom: pxvh(70);
        &__logo {
            width: pxvh(165);
            height: pxvh(30); }
        &__socials {
            padding-top: pxvh(72); }
        &__social {
            margin-right: pxvh(30);
            &:last-child {
                margin-right: 0; }
            &-icon {
                width: pxvh(16);
                height: pxvh(16);
                margin-right: pxvh(5);

                &_vkontakte {
                    width: pxvh(16);
                    height: pxvh(10);
                    margin-top: pxvh(3); }
                &_telegram {
                    width: pxvh(15);
                    height: pxvh(15);
                    margin-top: pxvh(1); }
                &_twitter {
                    width: pxvh(15);
                    height: pxvh(15);
                    margin-top: pxvh(1); }
                &_tiktok {
                    width: pxvh(15);
                    height: pxvh(15);
                    margin-top: pxvh(1); }
                &_dzen {
                    width: pxvh(15);
                    height: pxvh(15);
                    margin-top: pxvh(1); }
                &_viber {
                    width: pxvh(14);
                    height: pxvh(14);
                    margin-top: pxvh(1); }
                &_youtube {
                    width: pxvh(15);
                    height: pxvh(15);
                    margin-top: pxvh(1); }
                &_psgr {
                    width: pxvh(20);
                    height: pxvh(20); } }

            &-text {
                font-size: pxvh(13);
                line-height: pxvh(19); } }
        &__menu {
            margin-top: pxvh(34);
            &-item {
                font-size: pxvh(13);
                line-height: pxvh(28);
                &:before {
                    margin: 0 pxvh(8); } } }
        &__copyright {
            font-size: pxvh(13);
            line-height: pxvh(28); }
        &__lang {
            font-size: pxvh(15);
            margin-top: pxvh(68);
            &:before {
                width: pxvh(27);
                height: pxvh(19);
                border-radius: pxvh(4);
                margin-right: pxvh(7); } } }
    @include mediaRapture('mobile') {
        padding-bottom: pxvw(160);
        &__logo {
            height: pxvw(30);
            width: pxvw(164.62);
            margin-top: pxvw(50); }
        &__socials {
            margin-top: pxvw(52);
            flex-wrap: wrap;
            max-width: 90%;
            row-gap: pxvw(20); }
        &__social {
            margin-right: pxvw(32);
            &:last-child {
                margin-right: 0; }
            &-icon {
                width: pxvw(18);
                height: pxvw(18);

                &_psgr {
                    width: pxvw(20);
                    height: pxvw(20);
                    margin-top: 0; } }
            &-text {
                display: none; } }
        &__menu {
            display: flex;
            flex-wrap: wrap;
            padding: 0 pxvw(50);
            justify-content: center;
            margin-top: pxvw(28);
            &-item {
                font-size: pxvw(13);
                line-height: pxvw(28);
                &:before {
                    content: none; }
                &:after {
                    content: '•';
                    margin: 0 pxvw(7); }
                &:last-child:after {
                    content: none; } } }
        &__copyright {
            margin: 0;
            width: pxvw(228);
            text-align: center;
            font-size: pxvw(13);
            line-height: pxvw(28); }
        &__lang {
            font-size: pxvw(15);
            margin-top: pxvw(68);
            &:before {
                width: pxvw(27);
                height: pxvw(19);
                border-radius: pxvw(4);
                margin-right: pxvw(7); } } } }
