@import '../../mixins';

.button {
    border: 0;
    color: #fff;
    outline: none;
    cursor: pointer;
    display: block;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    transition: opacity 300ms ease-in-out;
    &.contained {
        text-align: center;
        transition: background-color 300ms ease-in-out;
        &.primary {
            background: $blueBrand;
            &:hover {
                background: #1781f3; }
            &:active {
                background: #205bd9; } }
        &.accent {
            background: #ff521f;
            &:hover {
                background: #f26e36; }
            &:active {
                background: #ef5325; } }
        .success & {
            background: #35C768 !important; } }
    &.outlined:hover {
        opacity: 0.7; }
    @include mediaRapture('desktop') {
        &.contained {
            font-size: pxvh(19);
            line-height: pxvh(60);
            border-radius: pxvh(10);
            box-shadow: 0 pxvh(4) pxvh(8) rgba(0, 0, 0, 0.06); }
        &.outlined {
            font-size: pxvh(15);
            padding: 0 pxvh(27);
            line-height: pxvh(34);
            border-radius: pxvh(23);
            border: pxvh(1) solid #fff; } }
    @include mediaRapture('mobile') {
        &.outlined {
            font-size: pxvw(15);
            padding: 0 pxvw(27);
            line-height: pxvw(34);
            border-radius: pxvw(23);
            border: pxvw(1) solid #fff; }
        &.contained {
            width: 100%;
            padding: pxvw(20, 320) pxvw(30);
            font-size: pxvw(18, 320);
            line-height: pxvw(23, 320);
            border-radius: pxvw(10);
            box-shadow: 0 pxvw(4) pxvw(8) rgba(0, 0, 0, 0.06); } } }
