* {
  box-sizing: border-box;
  &::before, &::after {
    box-sizing: border-box; } }
ul[class], ol[class] {
  padding: 0; }
body, h1, h2, h3, h4, p, ul[class], ol[class], li, figure, figcaption, blockquote, dl, dd {
  margin: 0; }
html {
  height: 100%; }
#root {
  display: flex;
  min-height: 100%;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch; }
body {
  height: 100%;
  scroll-behavior: smooth;
  text-rendering: optimizeSpeed;
  line-height: 1.5; }

ul[class], ol[class] {
  list-style: none; }
img {
  max-width: 100%;
  display: block; }
article > * + * {
  margin-top: 1em; }
input, button, textarea, select {
  font: inherit; }
a, button {
  cursor: pointer; }
@font-face {
  font-style: normal;
  font-weight: bold;
  font-family: 'Stapel';
  src: url('./assets/fonts/Stapel_Bold.eot');
  src: url('./assets/fonts/Stapel_Bold.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Stapel_Bold.woff2') format('woff2'), url('./assets/fonts/Stapel_Bold.woff') format('woff'), url('./assets/fonts/Stapel_Bold.ttf') format('truetype'); }

@font-face {
  font-style: normal;
  font-weight: normal;
  font-family: 'Stapel';
  src: url('./assets/fonts/Stapel_Regular.eot');
  src: url('./assets/fonts/Stapel_Regular.eot?#iefix') format('embedded-opentype'), url('./assets/fonts/Stapel_Regular.woff2') format('woff2'), url('./assets/fonts/Stapel_Regular.woff') format('woff'), url('./assets/fonts/Stapel_Regular.ttf') format('truetype'); }

