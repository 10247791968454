@import '../../mixins';

.hero {
    background: $blueBrand;
    &__inner {
        margin: 0 auto;
        display: flex; }
    &__content {
        color: #fff;
        flex-shrink: 0; }
    &__title {
        font-weight: bold;
        white-space: pre-wrap;
        font-family: 'Stapel', sans-serif; }
    &__decor {
        flex-shrink: 0;
        margin-left: auto;
        position: relative;
        &:before {
            top: 0;
            left: 0;
            opacity: 0;
            content: '';
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            background: no-repeat center/contain;
            animation: heroDecorIn 500ms 0ms forwards $bounceEasing;
            @include webpRetina('promo.png'); } }
    @include mediaRapture('desktop') {
        &__inner {
            width: pxvh(1200);
            padding-top: pxvh(41);
            padding-left: pxvh(22);
            padding-bottom: pxvh(76); }
        &__content {
            width: pxvh(467); }
        &__title {
            font-size: pxvh(53);
            line-height: pxvh(58);
            margin-top: pxvh(97); }
        &__text {
            font-size: pxvh(23);
            line-height: pxvh(34);
            margin-top: pxvh(12); }
        &__button {
            width: pxvh(240);
            margin-top: pxvh(42); }
        &__decor {
            width: pxvh(587);
            height: pxvh(473);
            filter: drop-shadow(0 pxvh(65) pxvh(110) rgba(0, 0, 0, 0.08)); } }
    @include mediaRapture('mobile') {
        height: pxvw(548, 320);
        &__inner {
            align-items: center;
            justify-content: start;
            flex-direction: column-reverse; }
        &__content {
            margin-top: pxvw(20, 320); }
        &__decor {
            margin: pxvw(30, 320) 0 0 0;
            width: pxvw(244, 320);
            height: pxvw(196, 320); }
        &__title {
            line-height: 1.12;
            width: pxvw(330);
            text-align: center;
            font-size: pxvw(40, 320);
            margin: pxvw(10) auto 0; }
        &__text {
            width: pxvw(330);
            text-align: center;
            font-size: pxvw(15, 320);
            line-height: pxvw(22, 320);
            margin: pxvw(10, 320) auto 0; }
        &__button {
            margin: pxvw(24, 320) auto 0; } } }
@keyframes heroDecorIn {
    from {
        opacity: 0;
        transform: scale(0) translateY(100%) rotateZ(-90deg); }
    to {
        opacity: 1;
        transform: scale(1) translateY(0%) rotateZ(0deg); } }
