@import '../../mixins';

.graph {
    position: relative;
    overflow-x: hidden;
    background: #FAFCFF;
    &__svg {
        top: 0;
        left: -1%;
        width: 102%;
        height: 100%;
        font-size: 0;
        display: block;
        position: absolute; }
    &__point {
        z-index: 1;
        display: flex;
        position: absolute;
        align-items: center;
        flex-direction: column-reverse;
        &:before,
        &:after {
            content: '';
            display: block;
            background: url(./graphPoint.svg) no-repeat center/contain; }
        &:before {
            opacity: 0;
            transform: scale(0);
            .inViewport & {
                animation: pointInAnimation 400ms 0ms $bounceEasing forwards; } }
        &:after {
            opacity: 0;
            transform: translateY(-150%);
            background: no-repeat center/contain;
            .inViewport & {
                animation: bubbleInAnimation 400ms 0ms $bounceEasing forwards; } }
        &:nth-child(1) {
            &:before {
                animation-delay: 500ms; }
            &:after {
                background-image: url(./bubble1.svg);
                animation-delay: 900ms; } }
        &:nth-child(2) {
            &:before {
                animation-delay: 1000ms; }
            &:after {
                background-image: url(./bubble2.svg);
                animation-delay: 1400ms; } }
        &:nth-child(3) {
            flex-direction: column;
            &:before {
                animation-delay: 1600ms; }
            &:after {
                background-image: url(./bubble3.svg);
                animation-delay: 2000ms; } } }
    @include mediaRapture('desktop') {
        width: 100%;
        height: 34.4vw;
        &__svg {
            height: 26.4vw;
            filter: drop-shadow(0 1.94vw 24px rgba(12, 115, 254, 0.7)) drop-shadow(0 5vw 40px rgba(12, 115, 254, 0.7));
            path {
                stroke-dasharray: 105vw;
                stroke-dashoffset: 105vw;
                .inViewport & {
                    animation: desktopGraphIn 2000ms 0ms ease-in forwards; } } }
        &__point {
            &:before {
                width: 2.777vw;
                height: 2.777vw;
                filter: drop-shadow(0 4/1440*100vw 24px rgba(12, 115, 254, 1)); }
            &:after {
                width: 119/1440*100vw;
                height: 60/1440*100vw;
                margin: 0 0 10/1440*100vw 0;
                filter: drop-shadow(0 12/1440*100vw 30px rgba(12, 115, 254, 0.3)); }
            &:nth-child(1) {
                top: 10.5vw;
                left: 7.56vw; }
            &:nth-child(2) {
                top: 14.6vw;
                left: 32.36vw; }
            &:nth-child(3) {
                top: 9.6vw;
                left: 70.76vw;
                &:after {
                    margin: 10/1440*100vw 0 0 0; } } } }
    @include mediaRapture('mobile') {
        width: 100%;
        height: pxvw(204);
        &__svg {
            top: 12vw;
            height: pxvw(77);
            filter: drop-shadow(0 pxvw(18) pxvw(12) rgba(12, 115, 254, 0.9));
            path {
                stroke-dasharray: pxvw(450);
                stroke-dashoffset: pxvw(375);
                .inViewport & {
                    animation: mobileGraphIn 2000ms 0ms ease-in forwards; } } }
        &__point {
            &:before {
                z-index: 1;
                width: pxvw(26);
                height: pxvw(26);
                filter: drop-shadow(0 pxvw(4) pxvw(24) rgba(12, 115, 254, 0.3)); }
            &:after {
                width: pxvw(81);
                height: pxvw(40);
                margin: 0 0 pxvw(6) 0;
                filter: drop-shadow(0 pxvw(6) pxvw(10) rgba(12, 115, 254, 0.2)); }
            &:nth-child(1) {
                top: 15.8vw;
                left: 6.9vw;
                &:before {
                    animation-delay: 700ms; }
                &:after {
                    animation-delay: 1100ms; } }
            &:nth-child(2) {
                top: 6.2vw;
                left: 72.86vw;
                &:before {
                    animation-delay: 1800ms; }
                &:after {
                    animation-delay: 2200ms; } }
            &:nth-child(3) {
                top: 19.6vw;
                left: 40.76vw;
                &:after {
                    margin: pxvw(6) 0 0 0;
                    animation-delay: 1600ms; }
                &:before {
                    animation-delay: 1200ms; } } } } }
@keyframes desktopGraphIn {
    from {
        stroke-dashoffset: 100vw; }
    to {
        stroke-dashoffset: 0; } }
@keyframes mobileGraphIn {
    from {
        stroke-dashoffset: 91vw; }
    to {
        stroke-dashoffset: -9vw; } }
@keyframes pointInAnimation {
    from {
        opacity: 0;
        transform: scale(0); }
    to {
        opacity: 1;
        transform: scale(1); } }
@keyframes bubbleInAnimation {
    from {
        opacity: 0;
        transform: translateY(-150%); }
    to {
        opacity: 1;
        transform: translateY(0); } }
