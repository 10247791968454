@import '../../mixins';

.promo {
    overflow: hidden;
    position: relative;
    background: #FAFCFF url(./promoBg.svg) no-repeat right top/cover;
    &__inner {
        position: relative;
        &:after {
            content: '';
            display: block;
            position: absolute; } }
    &__title {
        color: #fff;
        line-height: 1.12;
        font-weight: bold;
        font-family: 'Stapel', sans-serif; }
    &__text {
        color: #fff; }
    @include mediaRapture('desktop') {
        height: pxvh(967);
        padding-top: pxvh(327);
        &__inner {
            width: pxvh(1200);
            margin: 0 auto 0;
            &:after {
                top: pxvh(-176);
                right: pxvh(-512);
                width: pxvh(1076);
                height: pxvh(722);
                transform: translateX(100%);
                background: no-repeat center/contain;
                @include webpRetina('promoDecor.png');
                transition: transform 500ms $bounceEasing;
                filter: drop-shadow(0 pxvh(94) 30px #005AD4);
                .inViewport & {
                    transform: translateX(0); } } }
        &__title {
            width: pxvh(627);
            font-size: pxvh(54); }
        &__text {
            width: pxvh(604);
            margin-top: pxvh(7);
            font-size: pxvh(24);
            line-height: pxvh(42); } }
    @include mediaRapture('mobile') {
        overflow: initial;
        background: #0656FE url(./promoMobileBg.svg) no-repeat center top/contain;
        padding-top: pxvw(147);
        &__title {
            line-height: 1;
            width: pxvw(300);
            margin: 0 0 0 pxvw(25);
            font-size: pxvw(42); }
        &__text {
            width: pxvw(330);
            font-size: pxvw(18);
            line-height: pxvw(33);
            margin: pxvw(13) auto 0; }
        &__inner {
            padding-bottom: pxvw(324);
            &:after {
                left: 0;
                bottom: pxvw(-97);
                width: pxvw(644);
                height: pxvw(432);
                transform: translateX(100%);
                background: no-repeat center/contain;
                @include webpRetina('promoDecor.png');
                transition: transform 500ms $bounceEasing;
                filter: drop-shadow(0 pxvw(94) pxvw(65) #005AD4);
                .inViewport & {
                    transform: translateX(0); } } } } }
